import React from "react";
import "./App.css";
import { Route, Link, Redirect, Switch } from "react-router-dom";
import { Router } from "react-router";
import qs from "qs";
import { createBrowserHistory as createHistory } from "history";

import axios from "axios";
//TODO(leo):
// https://github.com/bih/spotify-web-playback-react-template/blob/glitch/src/App.js

//randomness ideas:
//use min/max (target shuts out unpopular stuff?)
//seed artist from a long list/db of artist names somewhere
//get all artists in user's lib- exclude them all

import SpotifyPlayer from "react-spotify-web-playback";

const request = axios.create({
  baseURL: "https://api.spotify.com/v1",
});

const history = createHistory();

const timeoutPromise = (time, resolveVal = undefined) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => resolve(resolveVal), time);
  });
};

/*
scopes
user-library-read playlist-read-private user-follow-read


second step: 
user-library-modify
user-follow-modify
playlist-modify-private
playlist-modify-public
*/

/*
spotify API calls
https://developer.spotify.com/documentation/web-api/reference-beta/#category-library

get user's saved tracks: 
GET https://api.spotify.com/v1/me/tracks
  (leave offf limit and offset, just use the https://developer.spotify.com/documentation/web-api/reference/object-model/#paging-object
    next link)
*/
const LIMIT = 50;

const TOO_MANY_REQUESTS_STATUS = 429;

const api = {
  getRandomSong: ({
    genreSeeds,
    acousticness,
    danceability,
    energy,
    valence,
    popularity,
    mode,
    instrumentalness,
  }) => {
    const queryParams = {
      target_acousticness: acousticness,
      target_danceability: danceability,
      target_energy: energy,
      target_valence: valence,
      target_popularity: popularity,
      target_mode: mode,
      // target_instrumentalness: instrumentalness,
      seed_genres: genreSeeds.join(","),
      limit: 1,
    };

    return request({ url: "/recommendations", params: queryParams });
  },

  getAllGenreSeeds: () => {
    const url = `/recommendations/available-genre-seeds`;
    return request({ url });
  },
  getProfile: () => request({ url: "https://api.spotify.com/v1/me" }),
};

const getHashParams = (hash) => {
  var hashParams = {};
  var e,
    r = /([^&;=]+)=?([^&;]*)/g,
    q = hash.substring(1);
  while ((e = r.exec(q))) {
    hashParams[e[1]] = decodeURIComponent(e[2]);
  }
  return hashParams;
};
const TOKEN_LS_KEY = "spotify_access_token";

const Button = (props) => <button {...{ type: "button", ...props }} />;

//TODO(leo): revert below
// const phaseOneScopes = "";

//player scopes
const embeddedScopes = [
  "streaming",
  "user-read-email",
  "user-read-private",
  "user-read-playback-state",
  "user-modify-playback-state",
  // "user-library-read",
  "user-library-modify",
];
const buildUrl = (nonEmbedded) => {
  console.log("nonEmbedded", nonEmbedded);
  const base = "https://accounts.spotify.com/authorize?";
  const params = [
    ["response_type", "token"],
    ["client_id", process.env.REACT_APP_SPOTIFY_CLIENT_ID],
    nonEmbedded ? null : ["scope", embeddedScopes],
    ["redirect_uri", process.env.REACT_APP_REDIRECT_URI],
    ["show_dialog", true],
  ].filter((x) => x);
  const fullURL = base + new URLSearchParams(params).toString();
  return fullURL;
};

//TODO - lt: vvv deal with isLocal https://developer.spotify.com/documentation/web-api/reference/object-model/#playlist-object-simplified

const Error = (props) => {
  return "OOOOOooops";
};

const repeat = (getEl, times) => {
  const arr = [];
  let i = times;
  while (i > 0) {
    arr.push(getEl(i));
    i--;
  }
  return arr;
};

const setAuthHeader = (token) => {
  request.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

//TODO - lt: vvv add api state param
class App extends React.Component {
  state = {
    hasErr: false,
    results: [],
    isAuthenticated: false,
    genreSeedsList: [],
    remainingGenreSeeds: [],
    token: null,
    currTrackURIs: [],
    isPremium: false,
    initialFetchComplete: false,
  };

  componentDidMount() {
    let savedTokenInfo = localStorage.getItem(TOKEN_LS_KEY);
    try {
      JSON.parse(savedTokenInfo);
    } catch (e) {
      savedTokenInfo = null;
    }
    if (savedTokenInfo) {
      const { token, expirationTimestamp } = JSON.parse(savedTokenInfo);
      if (Date.now() < expirationTimestamp) {
        setAuthHeader(token);
        clearTimeout(this.timeout);
        this.timeout = setTimeout(
          this.logout,
          expirationTimestamp - Date.now()
        );

        this.setState({ isAuthenticated: true, token });

        this.initUser();
      } else {
        this.logout();
      }
    }
  }

  logout = () => {
    localStorage.removeItem(TOKEN_LS_KEY);
    localStorage.removeItem("_spharmony_device_id");
    localStorage.removeItem("nonEmbedded");

    this.setState({ isAuthenticated: false, token: null });
  };

  nonEmbeddedLogout = () => {
    this.logout();
    localStorage.setItem("nonEmbedded", JSON.stringify(true));
  };

  initUser = () => {
    const isNonEmbeddedStr = localStorage.getItem("nonEmbedded");

    Promise.all([
      this.fetchGenreSeeds(),
      //TODO(leo): revert below
      this.getUserProfile(),
      // isNonEmbeddedStr ? Promise.resolve() : this.getUserProfile(),
    ]).then(() => {
      this.setState({ initialFetchComplete: true });
    });
  };

  getUserProfile = () => {
    return api.getProfile().then((resp) => {
      this.setState({ isPremium: resp.data.product === "premium" });
    });
  };

  //TODO(leo): vvv loading spinner
  fetchGenreSeeds = () => {
    return api
      .getAllGenreSeeds()
      .then(({ data }) => {
        const { genres } = data;
        this.setState({
          genreSeedsList: genres.slice(),
          remainingGenreSeeds: genres.slice(),
        });
      })
      .catch((err) => {
        this.logout();
      });
  };

  componentDidCatch(error, info) {
    this.setState({ hasErr: true });
    console.error("errby", { error, info });
  }

  authorize = () => {
    const isNonEmbeddedStr = localStorage.getItem("nonEmbedded");
    window.location = buildUrl(!!isNonEmbeddedStr);
  };
  //TODO(leo): how does isAuthenticated get set on first login?
  getNextRandomSong = () => {
    const { remainingGenreSeeds } = this.state;
    const genresCount = remainingGenreSeeds.length;
    const randomGenres = [];
    while (randomGenres.length < Math.min(3, genresCount)) {
      const nextRandIdx = Math.floor(Math.random() * genresCount);
      const nextGenre = remainingGenreSeeds[nextRandIdx];
      if (!randomGenres.includes(nextGenre)) {
        randomGenres.push(nextGenre);
      }
    }

    const newRemainingGenreSeeds = remainingGenreSeeds.filter(
      (s) => !randomGenres.includes(s)
    );

    this.setState({
      remainingGenreSeeds:
        newRemainingGenreSeeds.length > 0
          ? newRemainingGenreSeeds
          : this.state.genreSeedsList,
    });

    const params = {
      acousticness: Math.random(),
      danceability: Math.random(),
      energy: Math.random(),
      valence: Math.random(),
      // popularity: Math.floor(Math.random() * 101),
      max_popularity: 30,
      genreSeeds: randomGenres,
      // mode: Math.floor(Math.random() * 2),
      instrumentalness: Math.random(),
    };

    return api.getRandomSong(params);
  };

  render() {
    const {
      hasErr,
      isAuthenticated,
      currTrackURIs,
      initialFetchComplete,
      isPremium,
    } = this.state;
    return (
      <Router history={history}>
        <div className="App">
          <Switch>
            <Route
              path="/"
              exact
              render={({ location, match, history }) => {
                const isNonEmbeddedStr = localStorage.getItem("nonEmbedded");
                if (isAuthenticated) {
                  if (initialFetchComplete) {
                    return (
                      <Redirect
                        to={
                          !!isNonEmbeddedStr || !isPremium
                            ? "/song"
                            : "/embedded-song"
                        }
                      />
                    );
                  } else {
                    return "Loading...";
                  }
                } else {
                  return (
                    <>
                      <header className="App-header">
                        {hasErr ? (
                          <Error />
                        ) : (
                          <>
                            Get completely random songs from Spotify! Login
                            below to begin.
                          </>
                        )}
                      </header>
                      <Button onClick={() => this.authorize()}>
                        Login with Spotify
                      </Button>
                    </>
                  );
                }
              }}
            />
            <Route
              path="/embedded-song"
              render={({ location, match, history }) => {
                return isAuthenticated ? (
                  <div className="container">
                    <Button
                      onClick={() => {
                        Promise.all(repeat(this.getNextRandomSong, 5)).then(
                          (resps) => {
                            const trackURIs = resps.map(
                              (r) => r.data.tracks[0].uri
                            );
                            /*  

    },
    "external_urls" : {
      "spotify" : "https://open.spotify.com/track/5Z8CCpCTexeH36waq0T74y"
    },
    "href" : "https://api.spotify.com/v1/tracks/5Z8CCpCTexeH36waq0T74y",
    "id" : "5Z8CCpCTexeH36waq0T74y",
                          */
                            // window.open(track.external_urls.spotify, "_blank");
                            this.setState({ currTrackURIs: trackURIs });
                          }
                        );
                      }}
                    >
                      Get 5 Random Songs
                    </Button>
                    {currTrackURIs.length > 0 && (
                      <SpotifyPlayer
                        token={this.state.token}
                        autoPlay
                        showSaveIcon
                        uris={currTrackURIs}
                        callback={(state) => {
                          console.log(state);
                        }}
                      />
                    )}
                    <Button
                      className="margin-btn"
                      onClick={this.nonEmbeddedLogout}
                    >
                      Switch to non-embedded version (less convenient, but
                      possibly more random!)
                    </Button>
                    <Button
                      className="margin-btn"
                      onClick={this.logout}
                      children="Logout"
                    />
                  </div>
                ) : (
                  <Redirect to={"/"} />
                );
              }}
            />
            <Route
              path="/song"
              render={({ location, match, history }) => {
                return isAuthenticated ? (
                  <div className="container">
                    <Button
                      onClick={() => {
                        this.getNextRandomSong().then((resp) => {
                          const trackURI =
                            resp.data.tracks[0].external_urls.spotify;

                          window.open(trackURI, "_blank");
                          /*  

    },
    "external_urls" : {
      "spotify" : "https://open.spotify.com/track/5Z8CCpCTexeH36waq0T74y"
    },
    "href" : "https://api.spotify.com/v1/tracks/5Z8CCpCTexeH36waq0T74y",
    "id" : "5Z8CCpCTexeH36waq0T74y",
                          */
                          // window.open(track.external_urls.spotify, "_blank");
                        });
                      }}
                    >
                      Get A Random Song
                    </Button>
                    <Button onClick={this.logout} children="Logout" />
                  </div>
                ) : (
                  <Redirect to="/" />
                );
              }}
            />
            <Route
              path="/callback"
              render={({ location, match, history }) => {
                if (!this.state.isAuthenticated) {
                  const { access_token, expires_in } = getHashParams(
                    location.hash
                  );

                  console.log(getHashParams(location.hash));

                  const expTimeMs = Date.now() + expires_in * 1000;
                  clearTimeout(this.timeout);
                  this.timeout = setTimeout(this.logout, expires_in * 1000);
                  localStorage.setItem(
                    TOKEN_LS_KEY,
                    JSON.stringify({
                      token: access_token,
                      expirationTimestamp: expTimeMs,
                    })
                  );
                  setAuthHeader(access_token);
                  this.initUser();
                }
                return <Redirect to="/" />;
              }}
            />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
